import React, { useState } from 'react';
import logo from './assets/logo.png'; // Adjust the path if necessary

function App() {
  const [downloadSpeed, setDownloadSpeed] = useState(null);
  const [uploadSpeed, setUploadSpeed] = useState(null);
  const [testing, setTesting] = useState(false);

  const runSpeedTest = async () => {
    setTesting(true);
    setDownloadSpeed(null);
    setUploadSpeed(null);

    // Download speed test
    await testDownloadSpeed();

    // Upload speed test
    await testUploadSpeed();

    setTesting(false);
  };

  const testDownloadSpeed = async () => {
    const startTime = performance.now();
    const fileSize = 5 * 1024 * 1024; // 5MB file
    const url = `https://speed.cloudflare.com/__down?bytes=${fileSize}`;

    try {
      const response = await fetch(url);
      await response.arrayBuffer();
      const endTime = performance.now();
      const durationInSeconds = (endTime - startTime) / 1000;
      const speedMbps = (fileSize / durationInSeconds / (1024 * 1024)) * 8;

      setDownloadSpeed(speedMbps.toFixed(2));
    } catch (error) {
      console.error('Download speed test failed:', error);
      setDownloadSpeed('Error');
    }
  };

  const testUploadSpeed = async () => {
    const startTime = performance.now();
    const fileSize = 2 * 1024 * 1024; // 2MB file
    const url = 'https://speed.cloudflare.com/__up';

    try {
      const data = new ArrayBuffer(fileSize);
      const response = await fetch(url, {
        method: 'POST',
        body: data,
      });
      await response.text();
      const endTime = performance.now();
      const durationInSeconds = (endTime - startTime) / 1000;
      const speedMbps = (fileSize / durationInSeconds / (1024 * 1024)) * 8;

      setUploadSpeed(speedMbps.toFixed(2));
    } catch (error) {
      console.error('Upload speed test failed:', error);
      setUploadSpeed('Error');
    }
  };

  return (
    <div style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      justifyContent: 'center', 
      height: '100vh', 
      fontFamily: 'Arial, sans-serif',
      padding: '20px',
      boxSizing: 'border-box'
    }}>


      {/* Add the logo here */}
      <img 
        src={logo} 
        alt="Logo" 
        style={{ width: '100px', marginBottom: '20px' }} // Adjust width as needed
      />
      <h1>Internet Speed Test</h1>
      <button 
        onClick={runSpeedTest} 
        disabled={testing}
        style={{
          padding: '10px 20px',
          fontSize: '16px',
          cursor: 'pointer',
          marginBottom: '20px'
        }}
      >
        {testing ? 'Testing...' : 'Start Speed Test'}
      </button>
      <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%', maxWidth: '500px' }}>
        <div style={{ textAlign: 'center' }}>
          <p style={{ fontSize: '18px' }}>Download Speed</p>
          <p style={{ fontSize: '24px', fontWeight: 'bold' }}>
            {downloadSpeed !== null ? `${downloadSpeed} Mbps` : '-'}
          </p>
        </div>
        <div style={{ textAlign: 'center' }}>
          <p style={{ fontSize: '18px' }}>Upload Speed</p>
          <p style={{ fontSize: '24px', fontWeight: 'bold' }}>
            {uploadSpeed !== null ? `${uploadSpeed} Mbps` : '-'}
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;